import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import RadioSet from '../../../components/RadioSet/RadioSet';

const propertyType = [
  { label: 'Single Family Home', value: 'single-family-home' },
  { label: 'Condominium', value: 'condominium' },
  { label: 'Multi Family Building', value: 'multi-family-building' },
  { label: 'Naked Land', value: 'naked-land' },
  { label: 'Commercial', value: 'commercial' },
];

const PropertyType = ({ property }) => {
  const s = useLocation().search;

  const meta = {
    formatted_address: new URLSearchParams(useLocation().search).get('p'),
    landing_page_url: Cookies.get('landing_page_url'),
    referrer_url: Cookies.get('referrer_url'),
    _fbc: Cookies.get('_fbc'),
    _fbp: Cookies.get('_fbp'),
    navigator_user_agent: navigator.userAgent,
  };

  return (
    <>
      <RadioSet
        name="property_type"
        choices={propertyType}
        title="Which kind of property do you want to estimate?"
        property={property}
        insert={property.id == null}
        redirectUrl={(formData) => `/${property.id || formData.id}/address${s}`}
        meta={meta}
      />
    </>
  );
};

PropertyType.defaultProps = {
  property: {},
};

PropertyType.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object,
};

export default PropertyType;
