import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoder = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const GoogleMapsAutosuggest = ({
  name,
  label,
  onChange,
  freeSolo,
  inputRef,
  defaultValue,
  initGeo,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState(defaultValue);
  const [options, setOptions] = React.useState([]);
  const [showValidate, setShowValidate] = React.useState(defaultValue === '');

  if (typeof window !== 'undefined') {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAP_KEY}&libraries=places&callback=initMap`,
        document.querySelector('head'),
        'google-maps'
      );
    }
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current || !geocoder.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: 'us' } },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const geocode = ({ placeId, address }) => {
    if (!geocoder.current) return;
    setShowValidate(false);
    geocoder.current.geocode({ placeId, address }, (data) => {
      if (data.length === 0) return;
      const res = {
        formatted_address: data[0].formatted_address,
        address: {},
      };
      data[0].address_components.forEach((c) => {
        if (c.types.includes('route')) res.address.route = c.long_name;
        if (c.types.includes('street_number'))
          res.address.street_number = c.long_name;
        if (c.types.includes('sublocality')) res.address.locality = c.long_name;
        if (c.types.includes('locality')) res.address.locality = c.long_name;
        if (c.types.includes('postal_town')) res.address.locality = c.long_name;
        if (c.types.includes('postal_code'))
          res.address.postal_code = c.long_name;
        if (c.types.includes('administrative_area_level_1')) {
          res.address.state = c.short_name;
          res.address.state_long = c.long_name;
        }
        if (c.types.includes('country')) res.address.country = c.short_name;
      });
      res.lat = data[0].geometry.location.lat();
      res.lng = data[0].geometry.location.lng();
      document.activeElement.blur();
      onChange(res);
    });
  };

  window.initMap = () => {
    // eslint-disable-next-line
    autocompleteService.current = new window.google.maps.places.AutocompleteService();
    geocoder.current = new window.google.maps.Geocoder();
    if (inputValue.length && initGeo) geocode({ address: inputValue });
  };

  return (
    <>
      <Autocomplete
        id="google-map-demo"
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        freeSolo={freeSolo}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          if (!newValue) return;
          geocode({ placeId: newValue.place_id });
          document.activeElement.blur();
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line
            {...params}
            inputProps={{
              ...params.inputProps,
              value: inputValue,
            }}
            label={label}
            inputRef={inputRef}
            name={name}
            variant="outlined"
            fullWidth
            type="search"
            autoComplete="off"
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    // eslint-disable-next-line
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      {showValidate && (
        <Button
          onClick={() => geocode({ address: inputValue })}
          color="primary"
          fullWidth
          style={{ marginTop: 7 }}
        >
          Validate
        </Button>
      )}
    </>
  );
};

GoogleMapsAutosuggest.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  freeSolo: PropTypes.bool.isRequired,
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  initGeo: PropTypes.bool.isRequired,
};

GoogleMapsAutosuggest.defaultProps = {
  defaultValue: '',
};

export default GoogleMapsAutosuggest;
