import React from 'react';

const Container = ({ children }) => {
  return (
    <div className="container text-center min-100 d-flex flex-column">
      {children}
    </div>
  );
};

export default Container;
