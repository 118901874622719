import React from 'react';
import {
  Link,
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';

import { GET_PROPERTY } from './graphql';

import Address from './Steps/Address';
import Code from './Steps/Code';
import Contact from './Steps/Contact';
import Horizon from './Steps/Horizon';
import Occupation from './Steps/Occupation';
import Phone from './Steps/Phone';
import Price from './Steps/Price';
import PropertyType from './Steps/PropertyType';
import Reason from './Steps/Reason';
import Relation from './Steps/Relation';
import Result from './Steps/Result';

const steps = [
  'type',
  'address',
  'relation',
  'horizon',
  'reason',
  'occupation',
  'price',
  'contact',
  'phone',
  'code',
  'result',
];

const Property = () => {
  const { id } = useParams();
  const { url } = useRouteMatch();
  const location = useLocation();
  const { error, loading, data } = useQuery(GET_PROPERTY, {
    variables: { id },
  });

  const property = {
    id,
    formatted_address: new URLSearchParams(useLocation().search).get('p'),
    ...(data || {}).properties_by_pk,
  };

  if (loading)
    return (
      <>
        <LinearProgress variant="indeterminate" />
        <Typography variant="h1">Loading...</Typography>
      </>
    );

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  const currentStep = location.pathname.slice(1).split('/')[1];

  const progress = {
    type: 10,
    address: 20,
    relation: 40,
    reason: 45,
    horizon: 50,
    occupation: 60,
    price: 70,
    contact: 80,
    phone: 90,
    code: 95,
    results: 100,
  }[currentStep];

  return (
    <>
      <Helmet>
        <title>{property.formatted_address}</title>
      </Helmet>
      <LinearProgress variant="determinate" value={progress} />
      <TransitionGroup exit={false}>
        <CSSTransition
          appear
          key={location.key}
          classNames="fade"
          timeout={500}
        >
          <Switch location={location}>
            <Redirect exact from={url} to={`${url}/address`} />
            <Route path={`${url}/address`}>
              <Address property={property} />
            </Route>
            <Route path={`${url}/type`}>
              <PropertyType property={property} />
            </Route>
            <Route path={`${url}/relation`}>
              <Relation property={property} />
            </Route>
            <Route path={`${url}/reason`}>
              <Reason property={property} />
            </Route>
            <Route path={`${url}/horizon`}>
              <Horizon property={property} />
            </Route>
            <Route path={`${url}/occupation`}>
              <Occupation property={property} />
            </Route>
            <Route path={`${url}/price`}>
              <Price property={property} />
            </Route>
            <Route path={`${url}/contact`}>
              <Contact property={property} />
            </Route>
            <Route path={`${url}/phone`}>
              <Phone property={property} />
            </Route>
            <Route path={`${url}/code`}>
              <Code property={property} />
            </Route>
            <Route path={`${url}/result`}>
              <Result property={property} />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      {process.env.NODE_ENV === 'development' && (
        <>
          <nav>
            <ul
              className="pagination"
              style={{ overflow: 'scroll', marginTop: 50 }}
            >
              <li className="page-item">
                <Link className="page-link" to="/">
                  Home
                </Link>
              </li>
              {steps.map((l) => (
                <li key={l} className="page-item">
                  <Link className="page-link" to={`/${id}/${l}`}>
                    {l}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className="alert alert-secondary text-left">
            <small>
              <pre>
                <code>
                  {JSON.stringify(
                    {
                      data,
                      property,
                    },
                    null,
                    2
                  )}
                </code>
              </pre>
            </small>
          </div>
        </>
      )}
    </>
  );
};

export default Property;
