import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { v4 as uuid } from 'uuid';

import {
  UPDATE_PROPERTY,
  INSERT_PROPERTY,
  GET_PROPERTY,
} from '../../Routes/Property/graphql';

const RadioSet = ({
  property,
  meta,
  title,
  name,
  choices,
  insert,
  redirectUrl,
}) => {
  const history = useHistory();

  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [insertProperty] = useMutation(INSERT_PROPERTY);
  const { handleSubmit, control } = useForm({
    defaultValues: property,
  });

  const onSubmit = (formData) => {
    if (insert) {
      const id = uuid();
      insertProperty({
        variables: {
          object: {
            id,
            ...meta,
            ...formData,
          },
        },
        refetchQueries: [{ query: GET_PROPERTY, variables: { id } }],
      });
      return history.push(redirectUrl({ id }));
    }
    updateProperty({
      variables: {
        id: property.id,
        changes: {
          ...meta,
          ...formData,
        },
      },
    });
    return history.push(redirectUrl(formData));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={handleSubmit(onSubmit)}>
      <Typography variant="h1">{title}</Typography>
      <Controller
        name={name}
        control={control}
        as={
          <RadioGroup aria-label={name}>
            {choices.map((r) => (
              <FormControlLabel
                key={r.value}
                value={r.value}
                control={<Radio color="primary" />}
                label={r.label}
              />
            ))}
          </RadioGroup>
        }
      />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        disableElevation
        size="large"
        type="submit"
        style={{ marginTop: 10 }}
      >
        Next
      </Button>
    </form>
  );
};

RadioSet.defaultProps = {
  insert: false,
  redirectUrl: () => undefined,
};

RadioSet.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
  // eslint-disable-next-line
  meta: PropTypes.object,
  insert: PropTypes.bool,
  redirectUrl: PropTypes.func,
};

export default RadioSet;
