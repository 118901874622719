import React from 'react';
import PropTypes from 'prop-types';
import RadioSet from '../../../components/RadioSet/RadioSet';

const occupationType = [
  { value: 'owner_occupied', label: 'Owner occupied' },
  { value: 'tenant_occupied', label: 'Tenant occupied' },
  { value: 'vacant', label: 'Vacant' },
];

const Occupation = ({ property }) => (
  <RadioSet
    title="How is this property currently occupied?"
    choices={occupationType}
    name="occupation_type"
    property={property}
    redirectUrl={() => `/${property.id}/price`}
  />
);

Occupation.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Occupation;
