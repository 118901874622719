import React from 'react';
import PropTypes from 'prop-types';
import RadioSet from '../../../components/RadioSet/RadioSet';

const appraisalReason = [
  { value: 'rent', label: 'I am looking to rent my property' },
  { value: 'refinance', label: 'I am a looking to refinance' },
  { value: 'curious', label: 'I am just curious' },
];

const Reason = ({ property }) => (
  <RadioSet
    name="appraisal_reason"
    choices={appraisalReason}
    title="Why are your estimating this property?"
    property={property}
    redirectUrl={(formData) => {
      if (formData.appraisal_reason === 'sell')
        return `/${property.id}/horizon`;
      if (
        ['condominium', 'single-family-home'].includes(property.property_type)
      )
        return `/${property.id}/occupation`;
      return `/${property.id}/price`;
    }}
  />
);

Reason.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Reason;
