import React from 'react';
import logo from './logo.svg';

const Header = () => {
  return (
    <header className="App-header" style={{ height: 70 }}>
      <img
        src={logo}
        className="App-logo"
        alt="logo"
        style={{ height: 30, margin: 20 }}
      />
    </header>
  );
};

export default Header;
