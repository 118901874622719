import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { UPDATE_PROPERTY } from '../graphql';

const Price = ({ property }) => {
  const history = useHistory();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      perceived_value: property.perceived_value,
    },
  });

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const onSubmit = (formData) => {
    // eslint-disable-next-line
    const perceived_value = parseInt(
      `${formData.perceived_value}`.replace(/[^\d]+/gi, ''),
      10
    );

    updateProperty({
      variables: {
        id: property.id,
        changes: {
          perceived_value,
        },
      },
    });
    history.push(`/${property.id}/contact`);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Typography variant="h1">
          How much do you think that this property is worth?
        </Typography>
        <Typography variant="h2">
          This has no influence on the valuation.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="perceived_value"
              control={control}
              rules={{
                required: true,
              }}
              as={
                <NumberFormat
                  thousandSeparator
                  inputMode="numeric"
                  pattern="[0-9]*"
                  customInput={forwardRef((props, ref) => (
                    <TextField
                      // eslint-disable-next-line
                      {...props}
                      // eslint-disable-next-line
                      {...ref}
                      fullWidth
                      label="Perceived value"
                      variant="outlined"
                      inputProps={{
                        pattern: '[0-9]*',
                      }}
                    />
                  ))}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              size="large"
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

Price.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Price;
