import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const Estated = ({ data }) => (
  <>
    <Typography variant="h2" className="mt-4">
      Property information
    </Typography>
    <div className="text-left">
      <Typography variant="h3" gutterbottom>
        Parcel
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                APN
              </TableCell>
              <TableCell align="right">{data.parcel.apn_original}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Legal
              </TableCell>
              <TableCell align="right">
                {data.parcel.legal_description}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Area
              </TableCell>
              <TableCell align="right">
                {Intl.NumberFormat('en-US').format(data.parcel.area_sq_ft)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Land use
              </TableCell>
              <TableCell align="right">
                {data.parcel.standardized_land_use_type}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h3" gutterbottom>
        Deeds
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Buyers</TableCell>
              <TableCell>Sellers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.deeds.map((d) => (
              <TableRow key={d.document_id}>
                <TableCell component="th" scope="row">
                  {d.recording_date}
                </TableCell>
                <TableCell>{d.document_type}</TableCell>
                <TableCell align="right">
                  {Intl.NumberFormat('en-US').format(d.sale_price)}
                </TableCell>
                <TableCell>{`${d.buyer_last_name} ${d.buyer_first_name}`}</TableCell>
                <TableCell>{`${d.seller_last_name} ${d.seller_first_name}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h3">Assessments</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell align="right">Land value</TableCell>
              <TableCell align="right">Improvement value</TableCell>
              <TableCell align="right">Total value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.assessments.map((a) => (
              <TableRow key={a.year}>
                <TableCell component="th" scope="row">
                  {a.year}
                </TableCell>
                <TableCell align="right">
                  {Intl.NumberFormat('en-US').format(a.land_value)}
                </TableCell>
                <TableCell align="right">
                  {Intl.NumberFormat('en-US').format(a.improvement_value)}
                </TableCell>
                <TableCell align="right">
                  {Intl.NumberFormat('en-US').format(a.total_value)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </>
);

Estated.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object.isRequired,
};

export default Estated;
