import React from 'react';
import PropTypes from 'prop-types';
import RadioSet from '../../../components/RadioSet/RadioSet';

const userRelation = [
  { value: 'owner', label: 'I am the owner' },
  { value: 'buyer', label: 'I am a buyer' },
  { value: 'listing_agent', label: 'I am the listing agent' },
  { value: 'buyer_agent', label: `I am a buyer's agent` },
  { value: 'tenant', label: 'I am the tenant' },
  { value: 'other', label: 'Other' },
];

const Relation = ({ property }) => (
  <RadioSet
    title="How are you related to this property?"
    name="user_relation"
    choices={userRelation}
    property={property}
    redirectUrl={(formData) => {
      if (formData.user_relation === 'owner') return `/${property.id}/horizon`;
      if (
        ['condominium', 'single_family_home'].includes(formData.property_type)
      )
        return `/${property.id}/occupation`;
      return `/${property.id}/price`;
    }}
  />
);

Relation.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Relation;
