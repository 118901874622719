import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Apollo from './Providers/Apollo';
import Theme from './Providers/Theme';

import Routes from './Routes/Routes';
import Header from './components/Header/Header';
import Container from './components/Container/Container';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';

import './App.css';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.dataLayer.push({
      event: 'pageview',
    });
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router basename="/estimate">
      <ScrollToTop />
      <Apollo>
        <Theme>
          <Container>
            <Header />
            <Main>
              <Routes />
            </Main>
            <Footer />
          </Container>
        </Theme>
      </Apollo>
    </Router>
  );
};

export default App;
