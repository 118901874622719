import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { UPDATE_PROPERTY } from '../graphql';
import GoogleMapsAutosuggest from '../../../components/GoogleMapsAutosuggest';

const Address = ({ property }) => {
  const { handleSubmit, register, setValue } = useForm({
    defaultValues: property,
  });
  const shrink = true;
  const [showComponents, setShowComponents] = useState(
    property.locality != null
  );

  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const history = useHistory();

  const onSubmit = (formData) => {
    // eslint-disable-next-line
    console.log(formData);

    updateProperty({
      variables: {
        id: property.id,
        changes: formData,
      },
    });

    history.push(`/${property.id}/relation`);
  };

  const onChange = (googleData) => {
    setShowComponents(true);
    setValue('route', ((googleData || {}).address || {}).route);
    setValue('street_number', ((googleData || {}).address || {}).street_number);
    setValue('postal_code', ((googleData || {}).address || {}).postal_code);
    setValue('locality', ((googleData || {}).address || {}).locality);
    setValue('state', ((googleData || {}).address || {}).state);
    setValue('state_long', ((googleData || {}).address || {}).state_long);
    setValue('country', ((googleData || {}).address || {}).country);
    setValue('lat', (googleData || {}).lat);
    setValue('lng', (googleData || {}).lng);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1" style={{ marginBottom: 40 }}>
        What is the address?
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <GoogleMapsAutosuggest
            freeSolo
            onChange={onChange}
            label="Quick address search"
            inputRef={register}
            defaultValue={property.formatted_address || ''}
            initGeo={!showComponents}
            name="formatted_address"
          />
        </Grid>
        {showComponents && (
          <>
            <Grid item xs={3}>
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                name="street_number"
                label="House #"
                variant="outlined"
                InputLabelProps={{ shrink }}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                name="route"
                label="Street"
                variant="outlined"
                InputLabelProps={{ shrink }}
              />
            </Grid>
            {property.property_type === 'condominium' && (
              <Grid item xs={12}>
                <TextField
                  inputRef={register({ required: true })}
                  fullWidth
                  name="unit"
                  label="Unit"
                  variant="outlined"
                  InputLabelProps={{ shrink }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                name="locality"
                label="City"
                variant="outlined"
                InputLabelProps={{ shrink }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                name="state_long"
                label="State"
                variant="outlined"
                InputLabelProps={{ shrink }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                inputRef={register({ required: true })}
                fullWidth
                name="postal_code"
                label="Zip code"
                variant="outlined"
                InputLabelProps={{ shrink }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                size="large"
                type="submit"
              >
                Next
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <input type="hidden" name="state" ref={register} />
      <input type="hidden" name="country" ref={register} />
      <input type="hidden" name="lat" ref={register} />
      <input type="hidden" name="lng" ref={register} />
    </form>
  );
};

Address.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Address;
