import { gql } from '@apollo/client';

const fragment = `
    id
    property_type
    formatted_address
    route
    street_number
    unit
    postal_code
    locality
    state
    state_long
    country
    lat
    lng
    appraisal_reason
    occupation_type
    mobile
    user_relation
    sale_horizon
    perceived_value
    given_name
    family_name
    email
    sms_verified_at
    low_estimate
    value_estimate
    high_estimate
    estateds(order_by: {created_at: desc}, limit: 1) {
      response
    }
`;

export const INSERT_PROPERTY = gql`
  mutation InsertProperty($object: properties_insert_input!) {
    insert_properties_one(object: $object) {
      ${fragment}
    }
  }
`;

export const UPDATE_PROPERTY = gql`
  mutation UpdateProperty($id: uuid!, $changes: properties_set_input!) {
    update_properties_by_pk(pk_columns: { id: $id }, _set: $changes) {
      ${fragment}
    }
  }
`;

export const GET_PROPERTY = gql`
  query GetProperty($id: uuid!) {
    properties_by_pk(id: $id) {
      ${fragment}
    }
  }
`;

export const SEND_SMS = gql`
  mutation SendCode($id: uuid!) {
    sendSMS(propertyId: $id) {
      error
      success
    }
  }
`;

export const ESTIMATE = gql`
  mutation Estimate($id: uuid!) {
    estimate(propertyId: $id) {
      error
      success
      response
    }
  }
`;

export const VERIFY_CODE = gql`
  mutation VerifyCode($propertyId: uuid!, $smsVerificationCode: Int!) {
    verifyCode(
      propertyId: $propertyId
      smsVerificationCode: $smsVerificationCode
    ) {
      error
      success
    }
  }
`;
