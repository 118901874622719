import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { UPDATE_PROPERTY, SEND_SMS } from '../graphql';

const Phone = ({ property }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [sendSMS] = useMutation(SEND_SMS);
  const history = useHistory();

  const { handleSubmit, errors, control } = useForm({
    defaultValues: {
      mobile: property.mobile,
    },
  });

  const onSubmit = (formData) => {
    setLoading(true);
    updateProperty({
      variables: {
        id: property.id,
        changes: {
          mobile: formData.mobile,
        },
      },
    })
      .then(() =>
        sendSMS({
          variables: {
            id: property.id,
          },
        })
      )
      .then(() => history.push(`/${property.id}/code`))
      .catch((e) => {
        setError(e.message);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1">Almost there...</Typography>
      <Typography variant="h2">
        In order to prevent abusive use of our free service, we require a
        verification code be sent by sms.
      </Typography>
      <Typography variant="h2">
        Please enter your mobile number to receive the code.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="mobile"
            control={control}
            rules={{
              required: true,
              validate: (value) => isValidPhoneNumber(value),
            }}
            as={
              <PhoneInput
                international
                name="mobile"
                placeholder="Enter phone number"
                inputComponent={forwardRef((props, ref) => {
                  /* eslint-disable */
                  return (
                    <TextField
                      {...props}
                      error={errors.mobile || error}
                      helperText={
                        errors.mobile
                          ? 'Please enter a valid mobile phone number'
                          : error
                      }
                      name="mobile"
                      type="tel"
                      variant="outlined"
                      inputRef={ref}
                    />
                  );
                })}
                defaultCountry="US"
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

Phone.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Phone;
