import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { UPDATE_PROPERTY } from '../graphql';

const Contact = ({ property }) => {
  const { handleSubmit, register } = useForm({
    defaultValues: property,
  });
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const history = useHistory();

  const onSubmit = (formData) => {
    // eslint-disable-next-line
    console.log(formData);

    updateProperty({
      variables: {
        id: property.id,
        changes: formData,
      },
    });
    history.push(`/${property.id}/phone`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1">
        Where can we send the valuation report?
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            name="given_name"
            label="First name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            name="family_name"
            label="Last name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={register({ required: true })}
            fullWidth
            type="email"
            name="email"
            label="Email"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            type="submit"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

Contact.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Contact;
