import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useParams } from 'react-router-dom';

const Footer = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const params = useParams();
  return (
    <footer style={{ paddingTop: 50 }}>
      {process.env.NODE_ENV === 'development' && (
        <div className="alert alert-secondary text-left">
          <small>
            <pre>
              <code>
                {JSON.stringify(
                  {
                    location,
                    params,
                    isAuthenticated,
                    env: process.env,
                  },
                  null,
                  2
                )}
              </code>
            </pre>
          </small>
        </div>
      )}
    </footer>
  );
};

export default Footer;
