import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { VERIFY_CODE } from '../graphql';

const Code = ({ property }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { handleSubmit, register } = useForm();
  const [verifyCode] = useMutation(VERIFY_CODE);
  const history = useHistory();

  const onSubmit = (formData) => {
    setLoading(true);
    verifyCode({
      variables: {
        propertyId: property.id,
        smsVerificationCode: parseInt(formData['otp-code'], 10),
      },
    })
      .then(() => history.push(`/${property.id}/result`))
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h1">SMS Verification Code</Typography>
      <Typography variant="h2">
        Please enter the code received by SMS in order to receive your appraisal
        report.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            inputRef={register({ required: true })}
            type="number"
            error={error}
            helperText={error}
            inputProps={{
              min: 1000,
              max: 9999,
              style: { textAlign: 'center' },
            }}
            fullWidth
            variant="outlined"
            name="otp-code"
            auto-complete="one-time-code"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

Code.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Code;
