import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LinearProgress from '@material-ui/core/LinearProgress';

import PropertyType from './Property/Steps/PropertyType';
import Property from './Property/Property';

const Routes = () => (
  <Switch>
    <Route path="/:id">
      <Property />
    </Route>
    <Route path="/">
      <Helmet>
        <title>Calculate your Home Value | RealAdvisor</title>
      </Helmet>
      <LinearProgress variant="determinate" value={5} />
      <PropertyType />
    </Route>
  </Switch>
);

export default Routes;
