import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1652F0',
    },
    secondary: {
      main: '#05B169',
      contrastText: '#ffffff',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiLinearProgress: {
      root: {
        marginBottom: 30,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 8,
      },
      // Name of the rule
      sizeLarge: {
        // Some CSS
        padding: '18px 22px',
        fontSize: '1.1rem',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
    },
    MuiFormControlLabel: {
      root: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 8,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 10,
        padding: '7px 5px',
      },
    },
    MuiRadio: {
      colorPrimary: {
        '&$checked + span': {
          color: '#1652F0',
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '26px',
        margin: '10px auto 30px',
      },
      h2: {
        fontSize: '18px',
        lineHeight: '26px',
        margin: '-10px auto 35px',
      },
      h3: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: '500',
        margin: '30px auto 20px',
      },
    },
    MuiTable: {
      root: {
        background: '#fafafa',
      },
    },
  },
});

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
