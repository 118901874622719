import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';
import { ESTIMATE } from '../graphql';
import Estated from '../../../components/Estated';

const Result = ({ property }) => {
  const [
    estimate,
    { data: result, loading: mutationLoading, error: mutationError },
  ] = useMutation(ESTIMATE);
  useEffect(() => {
    // Trigger GTM event for conversions
    window.dataLayer.push({
      event: 'valuation',
      eventID: `valuation:${property.id}`,
      sale_horizon: property.sale_horizon,
    });
    if (
      ['asap', '6_to_12_months', '12_to_24_months'].indexOf(
        property.sale_horizon
      ) >= 0
    ) {
      window.dataLayer.push({
        event: 'seller_lead',
        eventID: `seller_lead:${property.id}`,
      });
    }

    // Run the estimate mutation
    estimate({
      variables: {
        id: property.id,
      },
      // eslint-disable-next-line
    }).catch(console.error);
  }, []);

  return (
    <>
      <Typography variant="h1">Valuation results</Typography>
      {mutationLoading && (
        <>
          <Typography variant="h2">Loading...</Typography>
          <CircularProgress />
        </>
      )}
      {result &&
        (result.estimate.response.median > 0 ? (
          <h1>
            USD $
            {Intl.NumberFormat('en-US').format(
              1000 * Math.round(result.estimate.response.median / 1000)
            )}
          </h1>
        ) : (
          <Alert severity="warning">No estimate found at this address</Alert>
        ))}
      {result && result.estimate.response.estated.data && (
        <Estated data={result.estimate.response.estated.data} />
      )}

      {mutationError && (
        <div className="text-left">
          <Alert severity="error">
            <AlertTitle>Valuation failed</AlertTitle>
            {mutationError.message}
          </Alert>
        </div>
      )}
      {process.env.NODE_ENV === 'development' && (
        <div className="mt-5 alert alert-info text-left">
          <small>
            <pre>
              <code>{JSON.stringify(result, null, 2)}</code>
            </pre>
          </small>
        </div>
      )}
    </>
  );
};

Result.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Result;
