import React from 'react';
import PropTypes from 'prop-types';
import RadioSet from '../../../components/RadioSet/RadioSet';

const saleHorizon = [
  { value: 'asap', label: 'As soon as possible' },
  { value: '6_to_12_months', label: 'In 6 to 12 months' },
  { value: '12_to_24_months', label: 'Maybe next year' },
  { value: 'over_24_months', label: 'In more than 2 years' },
  { value: 'on_market', label: 'Already on the market' },
  { value: undefined, label: 'I am not selling yet' },
];

const Reason = ({ property }) => (
  <RadioSet
    title="When are you looking to sell your property?"
    choices={saleHorizon}
    name="sale_horizon"
    property={property}
    redirectUrl={(formData) => {
      if (!formData.sale_horizon) return `/${property.id}/reason`;
      if (
        ['condominium', 'single-family-home'].includes(property.property_type)
      )
        return `/${property.id}/occupation`;
      return `/${property.id}/price`;
    }}
  />
);

Reason.propTypes = {
  // eslint-disable-next-line
  property: PropTypes.object.isRequired,
};

export default Reason;
